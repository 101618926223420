import React from "react";
import {
  StyleSheet,
  Document,
  Font,
  Page,
  Text,
  View,
  Image
} from "@react-pdf/renderer";
import dayjs from "dayjs";
import { env } from "../../../config";
import { DateWiseProductMovementResponse } from "../../../api/type";
import { userCurrentRole } from "../../../utils/common-functions";

interface SingleMovementByDatePrintComponentProps {
  dateWiseProductMovement: DateWiseProductMovementResponse;
}

Font.register({
  family: "Noto Serif Bengali",
  src: "/fonts/NotoSerifBengali-Regular.ttf"
});

const styles = StyleSheet.create({
  page: {
    padding: "0.75in",
    fontFamily: "Noto Serif Bengali",
    position: "relative"
  },
  organizationLogo: {
    width: "60px",
    objectFit: "contain",
    alignSelf: "center"
  },
  organizationName: {
    fontSize: 12,
    textAlign: "center",
    fontWeight: "bold",
    marginBottom: 3
  },
  headerContent: {
    fontSize: 9,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: "auto"
  },
  headerContentDetails: {
    alignSelf: "center"
  },
  backgroundImage: {
    position: "absolute",
    top: "45%",
    width: "200px",
    opacity: 0.05,
    objectFit: "contain",
    alignSelf: "center"
  },
  header: {
    width: "100%"
  },
  headerTable: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    textAlign: "left",
    fontSize: 9
  },
  headerDetails: {
    width: "50%",
    border: "1px solid #dfdfdf",
    paddingHorizontal: 5,
    paddingVertical: 1
  },
  table: {
    width: "100%",
    marginTop: 10,
    borderLeft: "1px solid #dfdfdf",
    borderRight: "1px solid #dfdfdf",
    borderBottom: "1px solid #dfdfdf"
  },
  tableHeader: {
    backgroundColor: "#EBECF0",
    fontWeight: "semibold"
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    fontSize: 9,
    textAlign: "center",
    borderBottom: "1px solid #dfdfdf",
    backgroundColor: "#EBECF0"
  },
  serial: {
    width: "10%",
    borderRight: "1px solid #dfdfdf"
  },
  date: {
    width: "50%",
    borderRight: "1px solid #dfdfdf"
  },
  incoming: {
    width: "15%",
    borderRight: "1px solid #dfdfdf"
  },
  outgoing: {
    width: "15%",
    borderRight: "1px solid #dfdfdf"
  },
  count: {
    width: "10%"
  },
  expandTable: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    textAlign: "left",
    fontSize: 8,
    borderTop: "1px solid #dfdfdf"
  },
  no: {
    width: "4%",
    textAlign: "center"
  },
  operationNo: {
    width: "5%"
  },
  invoiceNo: {
    width: "9%"
  },
  operationType: {
    width: "10%"
  },
  from: {
    width: "15%"
  },
  to: {
    width: "15%"
  },
  trackingId: {
    width: "8%"
  },
  quantity: {
    width: "8%"
  },
  user: {
    width: "15%"
  },
  dateTime: {
    width: "11%"
  },
  note: {
    fontSize: 8,
    fontWeight: "normal",
    width: "100%"
  },
  signature: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: 9,
    fontWeight: "semibold",
    marginTop: 50
  },
  footer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    position: "absolute",
    fontSize: 6,
    color: "#666666",
    bottom: "0.50in",
    left: "0.75in",
    right: "0.75in",
    borderTop: "1px solid #000"
  }
});

export const SingleMovementByDatePrintComponent: React.FC<
  SingleMovementByDatePrintComponentProps
> = (props: SingleMovementByDatePrintComponentProps) => {
  return (
    <Document>
      <Page size="A4" orientation="landscape" style={styles.page}>
        <Image style={styles.backgroundImage} src={env.reportLogo} fixed />
        <View>
          <Image style={styles.organizationLogo} src={env.organizationLogo} />
          <Text style={styles.organizationName}>
            {env.organizationFullName}
          </Text>
          <View style={styles.header}>
            <View style={styles.headerTable}>
              <Text style={styles.headerDetails}>
                Report Type : Date Wise Single Product Movement Report
              </Text>
              <Text style={styles.headerDetails}>
                Start Time :{" "}
                {dayjs(props.dateWiseProductMovement.startTime).format(
                  "DD MMM, YYYY, hh:mm A"
                )}
              </Text>
            </View>
            <View style={styles.headerTable}>
              <Text style={styles.headerDetails}>
                Product Name :{" "}
                {props.dateWiseProductMovement.product.name + " "}
              </Text>
              <Text style={styles.headerDetails}>
                End Time :{"  "}
                {dayjs(props.dateWiseProductMovement.endTime).format(
                  "DD MMM, YYYY, hh:mm A"
                )}
              </Text>
            </View>
            <View style={styles.headerTable}>
              <Text style={styles.headerDetails}>
                Location Name :{" "}
                {props.dateWiseProductMovement.originLocation.name}
              </Text>
              <Text style={styles.headerDetails}>
                Total In : {props.dateWiseProductMovement.totalIn}
              </Text>
            </View>
            <View style={styles.headerTable}>
              <Text style={styles.headerDetails}>
                No of Operation : {props.dateWiseProductMovement.totalCount}
              </Text>
              <Text style={styles.headerDetails}>
                Total Out : {props.dateWiseProductMovement.totalOut}
              </Text>
            </View>
          </View>
        </View>

        <View style={styles.table}>
          <View style={[styles.tableHeader, styles.row]}>
            <Text style={styles.serial}>Serial</Text>
            <Text style={styles.date}>Date</Text>
            <Text style={styles.incoming}>Incoming</Text>
            <Text style={styles.outgoing}>Outgoing</Text>
            <Text style={styles.outgoing}>Count</Text>
          </View>
          {props.dateWiseProductMovement.dateWiseMovementSummary.map(
            (singleProduct, index) => (
              <View key={index}>
                <View style={styles.row} key={index} wrap={false}>
                  <Text style={styles.serial}>{index + 1} </Text>
                  <Text style={styles.date}>
                    {dayjs(singleProduct.date).format("MMM D, YYYY")}
                  </Text>
                  <Text style={styles.incoming}>{singleProduct.inComing}</Text>
                  <Text style={styles.outgoing}>{singleProduct.outGoing}</Text>
                  <Text style={styles.outgoing}>{singleProduct.count}</Text>
                </View>
                <View>
                  <View style={styles.expandTable}>
                    <Text style={styles.no}>No.</Text>
                    <Text style={styles.operationNo}>Op. No</Text>
                    <Text style={styles.invoiceNo}>Invoice No</Text>
                    <Text style={styles.operationType}>Operation Type</Text>
                    <Text style={styles.from}>From</Text>
                    <Text style={styles.to}>To</Text>
                    <Text style={styles.trackingId}>Tracking ID</Text>
                    <Text style={styles.quantity}>Quantity</Text>
                    <Text style={styles.user}>User</Text>
                    <Text style={styles.dateTime}>Date & Time</Text>
                  </View>
                  {singleProduct.history.map((productDetails, index) =>
                    productDetails.notes &&
                    productDetails.notes !== null &&
                    productDetails.notes !== "" ? (
                      <View>
                        <View key={index} style={styles.expandTable}>
                          <Text style={styles.no}>{index + 1} </Text>
                          <Text style={styles.operationNo}>
                            {productDetails.stockOperationId}
                          </Text>
                          <Text style={styles.invoiceNo}>
                            {productDetails.invoiceNo}
                          </Text>
                          <Text style={styles.operationType}>
                            {productDetails.operationType}
                          </Text>
                          <Text style={styles.from}>
                            {productDetails.originLocation.name}
                          </Text>
                          <Text style={styles.to}>
                            {productDetails.destinationLocation?.name}
                          </Text>
                          <Text style={styles.trackingId}>
                            {productDetails.trackingNumber}
                          </Text>
                          <Text style={styles.quantity}>
                            {productDetails.quantity}
                          </Text>
                          <Text style={styles.user}>
                            {productDetails.createdBy.name}
                          </Text>
                          <Text style={styles.dateTime}>
                            {dayjs(productDetails.createdAt).format(
                              "DD MMM, YY hh:mm A"
                            )}
                          </Text>
                        </View>
                        <View>
                          <Text
                            style={[
                              {
                                fontSize: 8,
                                fontWeight: "semibold",
                                borderBottom: "1px solid #dfdfdf",
                                paddingHorizontal: 10
                              }
                            ]}
                          >
                            Note :{" "}
                            <Text style={styles.note}>
                              {productDetails.notes}
                            </Text>
                          </Text>
                        </View>
                      </View>
                    ) : (
                      <View key={index} style={styles.expandTable}>
                        <Text style={styles.no}>{index + 1} </Text>
                        <Text style={styles.operationNo}>
                          {productDetails.stockOperationId}
                        </Text>
                        <Text style={styles.invoiceNo}>
                          {productDetails.invoiceNo}
                        </Text>
                        <Text style={styles.operationType}>
                          {productDetails.operationType}
                        </Text>
                        <Text style={styles.from}>
                          {productDetails.originLocation.name}
                        </Text>
                        <Text style={styles.to}>
                          {productDetails.destinationLocation?.name}
                        </Text>
                        <Text style={styles.trackingId}>
                          {productDetails.trackingNumber}
                        </Text>
                        <Text style={styles.quantity}>
                          {productDetails.quantity}
                        </Text>
                        <Text style={styles.user}>
                          {productDetails.createdBy.name}
                        </Text>
                        <Text style={styles.dateTime}>
                          {dayjs(productDetails.createdAt).format(
                            "DD MMM, YY hh:mm A"
                          )}
                        </Text>
                      </View>
                    )
                  )}
                </View>
              </View>
            )
          )}
        </View>
        <View style={styles.signature}>
          <View>
            <Text>Verified By,</Text>
          </View>
          <View>
            <Text>Authorized By,</Text>
          </View>
        </View>
        <View style={styles.footer} fixed>
          <View>
            <Text>Powered By Bindulogic Limited</Text>
          </View>
          <View>
            <Text
              render={({ pageNumber, totalPages }) =>
                `Page ${pageNumber} of ${totalPages}`
              }
            />
          </View>
          <View>
            <Text>
              Printed By: {userCurrentRole() || "Unknown"},{" "}
              {dayjs().format("DD MMM YYYY, hh:mm A")}
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};
